import React from "react"
import useFetchData from '../../hooks/use-fetchData'

import SkeletonLoader from '../../components/SkeletonLoader'
import Meta from '../../components/Meta'
import ChapterBanner from '../../components/ChapterBanner';
import ImageRow from '../../components/ImageRow';
import ParagraphRow from '../../components/ParagraphRow';
import FloorPlanRow from '../../components/FloorPlanRow'
import "./style.scss";

export default function ClubLucca() {
    const { seoData, pageData, isLoading } = useFetchData('club-lucca');

    if (isLoading) {
        return (
            <SkeletonLoader />
        );
    }

    return (
        <>
            <Meta data={seoData} />
            {
                pageData.map((component, i) => {
                    if (component.type == "chapterBannerRow") {
                        return (
                            <ChapterBanner key={i} data={component.data} prev="location" next="masters" />
                        )
                    }

                    if (component.type == "imageRow") {
                        return (
                            <ImageRow key={i} data={component.data} />
                        )
                    }

                    if (component.type == "paragraphRow") {
                        return (
                            <ParagraphRow key={i} data={component.data.paragraph}  />
                        )
                    }

                    if (component.type == "floorPlanRow") {
                        return (
                            <FloorPlanRow key={i} data={component.data} />
                        )
                    }

                    
                })
            }
            
            
        </>
    )
}