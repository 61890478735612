import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser';
import VisibilitySensor from 'react-visibility-sensor';
import { useTranslation } from 'react-i18next';
import {CSSTransition} from 'react-transition-group';
import Disclaimer from '../../components/Disclaimer'

import "./style.scss";

export default function LocationPop({landmark, currentPop, togglePopup, currentCateogryColor}) {

    const { t } = useTranslation();
    const random =  (Math.random() ).toFixed(2);
    
    return (

        <div className={
            `pinItem ${currentPop==landmark.landmarkName ? ' active': ''} 
            ${landmark.landmarkXCoordinate > 40 ? ' reverse' : ''}
            `
        }
            style={{
                top:  window.innerWidth >820 ? landmark.landmarkYCoordinate+"%" : landmark.landmarkYCoordinateMobile+"%",
                left: window.innerWidth >820 ? landmark.landmarkXCoordinate+"%" : landmark.landmarkXCoordinateMobile+"%",
            }}
        >
            <a className="pin" onClick={()=>togglePopup(landmark.landmarkName)} >
                {
                    currentPop==landmark.landmarkName &&
                    <div className="pinBg" style={{borderColor:currentCateogryColor,opacity:1}}>
                    </div>
                }
                {
                    currentPop!=landmark.landmarkName &&
                    <div className="pinBg" style={{animationDelay: `${random}s`,backgroundColor:currentCateogryColor,opacity:.7}}>
                    </div>
                }
                
                <div className="pinImage" style={{backgroundColor:currentCateogryColor}}>
                    <img src={landmark.landmarkIcon.Source} alt="" />
                </div>
            </a>
            <CSSTransition in={currentPop == landmark.landmarkName} timeout={0} classNames="fade">
                <div className={landmark.landmarkPopupImage ? "infoWindow" : "infoWindow infoWindow--noImage"}>
                    {
                        landmark.landmarkPopupImage &&
                        <div className="img">
                            <div className="imgWrapper" style={{
                                backgroundImage: "url("+landmark.landmarkPopupImage.Source+")"
                            }}>
                                {/* <div className="disclaimer-container">
                                    <Disclaimer data={landmark.landmarkPopupImageDisclaimer} />
                                </div> */}
                            </div>
                        </div>
                    }
                    <div className="title">{parse(t(landmark.landmarkName))}</div>
                </div>
            </CSSTransition>
        </div>
    )
}