import React, { useState, useContext, useEffect } from "react"
import useFetchData from '../../hooks/use-fetchData'
import parse from 'html-react-parser';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import {CSSTransition} from 'react-transition-group';
import { context } from "../../Context";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import SlidePrevButton from '../../components/SlidePrevButton'
import SlideNextButton from '../../components/SlideNextButton'
import Paragraph from '../../components/Paragraph';
import ExpandableButton from "../../components/ExpandableButton";
import LocationMap from "../../components/LocationMap";
import dot1 from '../../images/icons/dot1.svg';
import dot2 from '../../images/icons/dot2.svg';
import dot3 from '../../images/icons/dot3.svg';
import dot4 from '../../images/icons/dot4.svg';
import dot5 from '../../images/icons/dot5.svg';
import dot7 from '../../images/icons/dot7.svg';
import dot8 from '../../images/icons/dot8.svg';
import dot9 from '../../images/icons/dot9.svg';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import 'swiper/css';

import ImageWithDisclaimer from '../../components/ImageWithDisclaimer';

import SkeletonLoader from '../../components/SkeletonLoader'
import Meta from '../../components/Meta'

import ChapterBanner from '../../components/ChapterBanner';

import "./style.scss";

export default function Location() {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(context);
    const { seoData, pageData, isLoading } = useFetchData('location');
    const [ categoryId , setCategoryId ] = useState(0)
    const toggleCategory = (id) => {
        setCategoryId(id)
        setCategoryDropdownStatus(!categoryDropdownStatus)
    }

    const [ categoryDropdownStatus, setCategoryDropdownStatus ] = useState(false)
    const toggleLandmarkDropdown = () => {
        setCategoryDropdownStatus(!categoryDropdownStatus)
    }

    const [ railTable, setRailTable ] = useState(false)  
    const toggleRailTable = () => {
        setRailTable(!railTable)
        // if (state.responsive_mode)
        //     document.body.classList.toggle('modal-active');
    }

    const modalActive = () => {
        if (window.innerWidth <= 1194 || (!!navigator.maxTouchPoints && window.matchMedia("(orientation: landscape)").matches)) {
            document.body.classList.add('modal-active');
        }
    }

    const modalDeActive = () => {
        document.body.classList.remove('modal-active');
    }

    const [isLastLandscapeSlide, setIsLastLandscapeSlide] = useState(false)
    const [totalLandscapeSlides, setTotalLandscapeSlides] = useState(1)
    const [currentLandscapeSlide, setCurrentLandscapeSlide] = useState(1)

    if (isLoading) {
        return (
            <SkeletonLoader />
        );
    }

    return (
        <>
            <Meta data={seoData} />
            {
                pageData.map((component, i) => {
                    if (component.type == "chapterBannerRow") {
                        return (
                            <ChapterBanner key={i} data={component.data} prev="virtual-tour" next="club-lucca" />
                        )
                    }

                    if (component.type == "paragraphImageRow") {
                        return (
                            <div className="LocationFeature" key={i}>
                                <div className="left">
                                    <LazyLoadComponent>
                                        <ImageWithDisclaimer data={component.data.imageWithDisclaimers[0]} />
                                    </LazyLoadComponent>
                                </div>
                                <div className="right">
                                    <div className="content">
                                        <Paragraph header={component.data.paragraph.header} />
                                        <div className="circles">
                                            <div className="circle">
                                                <CircularProgressbar
                                                    value={11}
                                                    strokeWidth={1}
                                                    styles={buildStyles({
                                                        pathColor: '#FFFFFF',
                                                        trailColor: '#48593F',
                                                    })}
                                                />
                                                <p>11<span>{parse(t("Minute"))}</span></p>
                                                <h3>{parse(t("LocationFeatureNearby2"))}</h3>
                                            </div>
                                            <div className="circle">
                                                <CircularProgressbar
                                                    value={11}
                                                    strokeWidth={1}
                                                    styles={buildStyles({
                                                        pathColor: '#FFFFFF',
                                                        trailColor: '#48593F',
                                                    })}
                                                />
                                                <p>11<span>{parse(t("Minute"))}</span></p>
                                                <h3>{parse(t("LocationFeatureNearby3"))}</h3>
                                            </div>
                                            <div className="circle">
                                                <CircularProgressbar
                                                    value={12}
                                                    strokeWidth={1}
                                                    styles={buildStyles({
                                                        pathColor: '#FFFFFF',
                                                        trailColor: '#48593F',
                                                    })}
                                                />
                                                <p>12<span>{parse(t("Minute"))}</span></p>
                                                <h3>{parse(t("LocationFeatureNearby1"))}</h3>
                                            </div>
                                            <div className="circle">
                                                <CircularProgressbar
                                                    value={40}
                                                    strokeWidth={1}
                                                    styles={buildStyles({
                                                        pathColor: '#FFFFFF',
                                                        trailColor: '#48593F',
                                                    })}
                                                />
                                                <p>24<span>{parse(t("Minute"))}</span></p>
                                                <h3>{parse(t("LocationFeatureNearby4"))}</h3>
                                            </div>
                                        </div>
                                        <Paragraph header={component.data.paragraph.contentText} />
                                        <div className="rails">
                                            <div className="rail">
                                                <h3 className="green">{parse(t("LocationFeatureRail1"))}</h3>
                                            </div>
                                            <div className="rail">
                                                <h3 className="orange">{parse(t("LocationFeatureRail2"))}</h3>
                                            </div>
                                            <div className="rail">
                                                <h3 className="blue">{parse(t("LocationFeatureRail3"))}</h3>
                                            </div>
                                        </div>
                                        <ExpandableButton text={t("LocationFeatureRailButton")} click={() => {toggleRailTable(), modalActive()}} />
                                        {
                                            railTable &&
                                            <aside className={`${state.responsive_mode === true? 'popup_mode': ''}`}>
                                                <button onClick={() => {toggleRailTable(), modalDeActive()}} />
                                                <table cellPadding={0} cellSpacing={0}>
                                                    <thead>
                                                        <tr>
                                                            <th>{parse(t("tableheading1"))}</th>
                                                            <th>{parse(t("tableheading2"))}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><img src={dot1} /> <span>{parse(t("Line1"))}<small>{parse(t("SuggestedLine"))}</small></span></td>
                                                            <td><img src={dot7} /> <span>{parse(t("Line6"))}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><img src={dot2} /> <span>{parse(t("Line2"))}<small>{parse(t("SuggestedLine"))}</small></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><img src={dot3} /> <span>{parse(t("Line3"))}<small>{parse(t("SuggestedLine"))}</small></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><img src={dot4} /> <span>{parse(t("Line4"))}<small>{parse(t("SuggestedLine"))}</small></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><img src={dot5} /> <span>{parse(t("Line5"))}<small>{parse(t("SuggestedLine"))}</small></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><img src={dot8} /> <span>{parse(t("Line7"))}<small>{parse(t("SuggestedLine"))}</small></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><img src={dot9} /> <span>{parse(t("Line8"))}<small>{parse(t("SuggestedLine"))}</small></span></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                
                                            </aside>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            }
            {
                pageData.map((component, i) => {
                    if (component.type == 'landmarkRow') {
                        return (

                            <div className="landmarkRow" key={i}>
                                <LocationMap data={component.data}/>
                            </div>

                            // <div className="landmarkRow" key={i}>
                            //     <div className="left">
                            //         <div className="content">
                            //             <h2>{component.data.mapName}</h2>
                            //             {
                            //                 state.responsive_mode &&
                            //                 <div className='landmark_dropdown'>
                            //                     <button onClick={() => toggleLandmarkDropdown()}>{component.data.listedLandmarkItems[categoryId].landmarkCategory}</button>
                            //                     <CSSTransition
                            //                         in={categoryDropdownStatus}
                            //                         timeout={0}
                            //                         classNames="transitionSlideDown"
                            //                         >
                            //                             <nav>
                            //                                 {
                            //                                     component.data.listedLandmarkItems.map((item, i) => {
                            //                                         return (
                            //                                             <button
                            //                                                 className={`${i === categoryId ? 'active' : ''}`}
                            //                                                 onClick={() => toggleCategory(i)}
                            //                                                 key={i}
                            //                                             >
                            //                                                 {item.landmarkCategory}
                            //                                             </button>
                            //                                         )
                            //                                     })
                            //                                 }
                            //                         </nav>
                            //                     </CSSTransition>
                            //                     <ul className="landmarks">
                            //                         {
                            //                             component.data.listedLandmarkItems[categoryId].landmark.map((landmark, k) => {
                            //                                 return (
                            //                                     <li key={k}>
                            //                                         <img src={landmark.landmarkIcon.Source + '?format=webp'} width={landmark.landmarkIcon.Width} height={landmark.landmarkIcon.Height} alt={landmark.landmarkIcon.AltText} />
                            //                                         {landmark.landmarkName}
                            //                                     </li>
                            //                                 )
                            //                             })
                            //                         }
                            //                     </ul>
                            //                 </div>
                            //             }
                            //             {
                            //                 !state.responsive_mode &&
                            //                 <ul>
                            //                     {
                            //                         component.data.listedLandmarkItems.map((item, j) => {
                            //                             return (
                            //                                 <li key={j} onClick={() => toggleCategory(j)} className={`${categoryId === j? 'active': ''}`}>
                            //                                     {item.landmarkCategory}
                            //                                     <ul className="landmarks">
                            //                                         {
                            //                                             item.landmark.map((landmark, k) => {
                            //                                                 return (
                            //                                                     <li key={k}>
                            //                                                         <img src={landmark.landmarkIcon.Source + '?format=webp'} width={landmark.landmarkIcon.Width} height={landmark.landmarkIcon.Height} alt={landmark.landmarkIcon.AltText} />
                            //                                                         {landmark.landmarkName}
                            //                                                     </li>
                            //                                                 )
                            //                                             })
                            //                                         }
                            //                                     </ul>
                            //                                 </li>
                            //                             )
                            //                         })
                            //                     }
                            //                 </ul>
                            //             }
                                        
                            //         </div>
                                    
                            //     </div>
                            //     <div className="right">
                            //         <ImageWithDisclaimer type="location" data={component.data} />
                            //     </div>
                            // </div>
                        )
                    }

                    if (component.type == "landscapeRow") {
                        return (
                            <div className="LandscapeRow" key={i}>
                                <h2>{component.data.sliderName}</h2>
                                <Swiper
                                    watchOverflow
                                    onSwiper={(swiper) => {
                                        setTotalLandscapeSlides(swiper.slides.length)
                                    }}
                                    onUpdate={(swiper) => {
                                        setTotalLandscapeSlides(swiper.slides.length)
                                    }}
                                    onSlideChange={(e) => {
                                        
                                        setCurrentLandscapeSlide(e.realIndex + 1)
                                        if (!e.isEnd) {
                                            setIsLastLandscapeSlide(false)
                                        }
                                    }}
                                    onReachEnd={(swiper) => {
                                        setIsLastLandscapeSlide(true)
                                    }}
                                    
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1.5,
                                            slidesOffsetBefore: 20,
                                            spaceBetween: 40
                                        },
                                        429: {
                                            slidesPerView: 2.5,
                                            slidesOffsetBefore: 40,
                                            spaceBetween: 40
                                        },
                                        821: {
                                            slidesPerView: 3.5,
                                            slidesOffsetBefore: 100,
                                            spaceBetween: 50
                                        },
                                        1024: {
                                            slidesPerView: 4.5,
                                            slidesOffsetBefore: 100,
                                            spaceBetween: 50
                                        }
                                    }}
                                    >
                                        {
                                            totalLandscapeSlides > 1 &&
                                            <>
                                                <SlidePrevButton className={`${currentLandscapeSlide == 1? 'disabled': ''}`}  />
                                                <SlideNextButton className={`${isLastLandscapeSlide? 'disabled': ''}`}/>
                                            </>
                                        }
                                        {
                                            component.data.imageWithDisclaimer.map((item, i) => {
                                                return (
                                                    <SwiperSlide key={i}>
                                                        <LazyLoadComponent>
                                                            <ImageWithDisclaimer data={item} />
                                                            {
                                                                item.landscapeName &&
                                                                    <h2>{item.landscapeName}</h2>
                                                            }
                                                        </LazyLoadComponent>
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                </Swiper>
                            </div>
                        )
                    }
                })
            }
        </>
    )
}