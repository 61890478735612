import React, { useState, useEffect, useContext } from 'react'
import useFetchData from '../../hooks/use-fetchData'
import parse from 'html-react-parser';
import VisibilitySensor from 'react-visibility-sensor';
import { useTranslation } from 'react-i18next';
import {CSSTransition} from 'react-transition-group';

import LocationPop from '../LocationPop';
import iconDropdown from '../../images/icons/iconDropdown.svg'
import Disclaimer from '../../components/Disclaimer'

import { context } from "../../Context";

import "./style.scss";

export default function LocationMap(data) {

    const { t } = useTranslation();
    
    // const { seoData, pageData, isLoading } = useFetchData('location');
    const { state, dispatch } = useContext(context);
    const [currentCateogry, setCurrentCateogry] = useState(false);
    const [currentPop, setCurrentPop] = useState(false);
    const [menuToggleStatus, setMenuToggleStatus] = useState();
    const pageData = data;

    useEffect(() => {
        // console.log('pageData: ', pageData);
        // console.log('currentCateogry: ', currentCateogry);
        // console.log('currentPop: ', currentPop);
    });

    useEffect(() => {
        changeCategory(pageData.data.listedLandmarkItems[0].landmarkCategory);
      }, [1]); 

    const changeCategory = (category) => {
        setCurrentCateogry(category);
        setCurrentPop();
        setMenuToggleStatus(false);
        
    }
    const togglePopup = (id) => {
        if( currentPop == id ){
            setCurrentPop();
            return;
        }
        setCurrentPop(id);
    }

    const toggleMenu = () => {
        setMenuToggleStatus(!menuToggleStatus);
    }


    // console.log('LocationMap data: ', data);
    // console.log('listedLandmarkItems: ', data.data.listedLandmarkItems);
    
    return (

        <div className="locationMap">
            <div className="landmarkRowContent">
                <div className="landmarkRowNav--dropdown">
                    <div className="landmarkRowNav--dropdown-wrapper">
                        <nav className="custom-nav-dropdown">
                            <button onClick={() => toggleMenu()} >{parse(t(currentCateogry))}</button>
                            <CSSTransition in={menuToggleStatus} timeout={0} classNames="transitionSlideDown">
                                <div>
                                    {
                                        data.data.listedLandmarkItems.map((listedLandmarkItem, i)=>{
                                            return (
                                                <button className={ currentCateogry == listedLandmarkItem.landmarkCategory ? 'active' : 'test' }
                                                    onClick={() => { changeCategory(listedLandmarkItem.landmarkCategory) }} 
                                                    key={i}
                                                >{parse(t(listedLandmarkItem.landmarkCategory))}</button>
                                            )        
                                        })
                                    }
                                </div>
                            </CSSTransition>
                        </nav>
                    </div>
                </div>
                <div className="landmarkRowdiv">
                    <div className="landmarkRowTitle">{data.data.mapName}</div>
                    <nav className="landmarkRowNav">
                        {
                            data.data.listedLandmarkItems.map((listedLandmarkItem, i)=>{
                                return (
                                    <a key={i} className={currentCateogry == listedLandmarkItem.landmarkCategory? "active": ""} onClick={()=>changeCategory(listedLandmarkItem.landmarkCategory)}>{parse(t(listedLandmarkItem.landmarkCategory))}</a>
                                )        
                            })
                        }
                    </nav>
                </div>
                <div className="mapImgContainer">
                    <div className="mapImg">
                        <div className="mapImgWrapper">
                            <img className="base" src={data.data.map.Source} alt={data.data.map.AltText} />
                            
                        </div>
                        <div className="mapPins">
                            {
                                data.data.listedLandmarkItems.map((listedLandmarkItem, i)=>{
                                    return (
                                        <CSSTransition in={currentCateogry == listedLandmarkItem.landmarkCategory} timeout={0} key={i} classNames="fade">
                                            <div className="pinGroup">
                                                {/* {parse(t(listedLandmarkItem.landmarkCategory))} */}
                                                {
                                                    listedLandmarkItem.landmark.map((landmark, _i)=>{
                                                        return (
                                                            <LocationPop key={_i} landmark={landmark} currentPop={currentPop} togglePopup={togglePopup} currentCateogryColor={listedLandmarkItem.landmarkColor}/>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </CSSTransition>
                                    )        
                                })
                            }
                        </div>
                    </div>
                
                    <div className="infoWindows">
                        {
                            data.data.listedLandmarkItems.map((listedLandmarkItem, i)=>{
                                return (
                                    <div key={i} className={currentCateogry == listedLandmarkItem.landmarkCategory ? 'active': ''}>
                                        {
                                            listedLandmarkItem.landmark.map((landmark, _i)=>{
                                                return (
                                                    <div className={ `infoWindow  ${currentPop == landmark.landmarkName ? 'active' : ''} ${!landmark.landmarkPopupImage ? "infoWindow--noImage" : ""} `} key={_i} >
                                                        {
                                                            landmark.landmarkPopupImage &&
                                                            <div className="img">
                                                                <div className="imgWrapper" style={{
                                                                    backgroundImage: "url("+landmark.landmarkPopupImage.Source+")"
                                                                }}>
                                                                    {/* <div className="disclaimer-container">
                                                                        <Disclaimer data={landmark.landmarkPopupImageDisclaimer} />
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="title">{parse(t(landmark.landmarkName))}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )        
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="disclaimer-container">
                <Disclaimer data={data.data.disclaimer} />
            </div>
        
        </div>
    )
}