import React from "react"
import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import './locales/i18n';

import "./styles/common.scss"

import Layout from './Layout';
import LuxuryDesign from './pages/LuxuryDesign';
import Residences from './pages/Residences';
import Location from './pages/Location';
import VirtualTour from './pages/VirtualTour';
import ClubLucca from './pages/ClubLucca';
import Masters from './pages/Masters';
import Gallery from './pages/Gallery';

import NoMatch from './pages/NoMatch';

import ScrollToTop from "./utilities/ScrollToTop";


export default function App() {
    return (
        <>
            <ScrollToTop>
                <Routes>
                    <Route index element={<Layout />} />
                    <Route path=":lang" element={<Layout />}>
                        <Route index element={<Navigate to="luxury-design" replace />} />
                        <Route path="luxury-design" element={<LuxuryDesign />} />
                        <Route path="residences" element={<Residences />} />
                        <Route path="location" element={<Location />} />
                        <Route path="virtual-tour" element={<VirtualTour />} />
                        <Route path="club-lucca" element={<ClubLucca />} />
                        <Route path="masters" element={<Masters />} />
                        <Route path="gallery" element={<Gallery />} />
                        <Route path="*" element={<NoMatch />} />
                    </Route>
                </Routes>
            </ScrollToTop>
        </>
    )
}